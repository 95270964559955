import gql from 'graphql-tag'

const mutation = gql`
  mutation createOutputProcessRequest( $walletId: ID! ) {
    createOutputProcessRequest( walletId: $walletId ) {
      outputProcessRequest {
        outputFileUrl
      }
    }
}`

/**
 * PaymentIntentID
 * @param {*} paymentIntentId
 */
async function createOutputProcessRequest ( walletId ) {
    const { $api } = Vue.prototype
    const result = await $api.mutate({
      mutation,
      variables: { walletId }
    }).then( res => res )
    .catch( e => e )
    return result;
}

export { createOutputProcessRequest }
