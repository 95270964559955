var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "tui ui accordion" }, [
      _c(
        "div",
        { staticClass: "wrap-accordion" },
        [
          _c(
            "div",
            {
              staticClass: "title",
              class: { active: _vm.activeStatus },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  _vm.activeStatus = !_vm.activeStatus
                }
              }
            },
            [
              _c("h3", { staticClass: "inner-title" }, [
                _c("span", { staticClass: "file-status" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$tc(
                        _vm.fileStatus[0],
                        _vm.fileStatus.length > 1 ? _vm.fileStatus[1] : 1
                      )
                    )
                  )
                ]),
                _vm.total
                  ? _c("span", { staticClass: "file-total" }, [
                      _vm._v("(" + _vm._s(_vm.total) + ")")
                    ])
                  : _vm._e()
              ]),
              _c("t-icon", {
                staticClass: "icon",
                attrs: {
                  icon: [
                    "fal",
                    !_vm.activeStatus ? "plus-square" : "minus-square"
                  ]
                }
              })
            ],
            1
          ),
          _c("transition", { attrs: { name: "fade" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeStatus,
                    expression: "activeStatus"
                  }
                ],
                staticClass: "table-files-wrap no-pagination"
              },
              [
                _c(
                  "table",
                  { staticClass: "ui basic table", class: _vm.fileStatus[0] },
                  [
                    _c("thead", [
                      _c(
                        "tr",
                        [
                          _vm._l(_vm.thead, function(head, i) {
                            return _c("th", {
                              key: i,
                              staticClass: "table-header",
                              class: _vm.getHeadClass(
                                head.name,
                                _vm.fileStatus[0]
                              ),
                              domProps: { innerHTML: _vm._s(head.label) }
                            })
                          }),
                          _c("th", [_vm._v(" ")])
                        ],
                        2
                      )
                    ]),
                    _c(
                      "tbody",
                      { staticClass: "basic-table-body" },
                      [
                        _vm.loading
                          ? _c(
                              "tr",
                              _vm._l([].concat(Array(5)), function(item, k) {
                                return _c(
                                  "td",
                                  { key: k },
                                  [
                                    _c("t-placeholder", { attrs: { lines: 1 } })
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          : _vm._l(_vm.filesList, function(items, i) {
                              return _c(
                                "tr",
                                { key: i, staticClass: "table-lines" },
                                [
                                  _vm._l(items, function(item, k) {
                                    return _c(
                                      "td",
                                      {
                                        key: k,
                                        class: _vm.solveColumnItem(item, k)
                                      },
                                      [
                                        _c("span", {
                                          domProps: { innerHTML: _vm._s(item) }
                                        })
                                      ]
                                    )
                                  }),
                                  _c(
                                    "td",
                                    {
                                      staticClass:
                                        "center aligned columns extra-options"
                                    },
                                    [
                                      _c("t-dropdown", {
                                        attrs: {
                                          icon: ["fas", "ellipsis-h"],
                                          extraClass: "top right",
                                          pointing: ""
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "items",
                                              fn: function() {
                                                return [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass: "item",
                                                      class: {
                                                        disabled:
                                                          _vm.disableAction ||
                                                          _vm.exceededTimeLimitExportFile()
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.getProcessRequest(
                                                            items.walletId,
                                                            items.paymentMethod
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("Gerar remessa")]
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                2
                              )
                            })
                      ],
                      2
                    )
                  ]
                )
              ]
            )
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }