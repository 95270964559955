
import { mapState, mapActions } from 'vuex'
import { createOutputProcessRequest } from '@/queries/files/file.mutations'
import filesMixin from '@/mixins/files.mixin'

export default {
  name: 'TFilesTableNewShipment',

  mixins: [ filesMixin ],

  components: { TTableFiles: () => import ('./table-files.vue') },

  data () {
    return {
      activeStatus: true,
      erros: false,
      fileStatus: ['New', 2],
      fileName: '',
      disableAction: false,
      thead: [
        { label: 'Forma de Pagamento', name: 'paymentMethod' },
        { label: 'Registros:', name: 'registers' },
        { label: 'Vencidos', name: 'delayed' },
        { label: 'Cobrança', name: 'billing' },
        { label: 'Recobrança', name: 'recovery' }
      ]
    }
  },

  props: {
    files: {
      type: Array,
      default: () => []
    },

    loading: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState({
      showBarStatus: ({ files }) => files.showBarStatus,
      fileListNewShipment: ({ files }) => files.fileListNewShipment,
      hasButtonBarStatus: ({ files }) => files.hasButtonBarStatus
    }),

    total () {
      return this.fileListNewShipment.length
    },

    filesList () {
      this.bindPlugin()
      return this.fileListNewShipment
    },

    fileDataFromTable: {
      get () {
        return { file: null, name: this.fileName }
      },
      set (value) {
        const fileName = value.idWallet.substring(0, value.idWallet.indexOf('-'));
        this.fileName = `ID ${fileName}... (${value.paymentMethod})`
      }
    }
  },

  methods: {
    ...mapActions('files', ['toggleFileHasErrors', 'toggleProcessCompleted', 'setUploadFileProgress', 'setUrlFileNewShipment', 'getYearListFiles',
    'getFilesNewShipment', 'getFilesWaitingReturn', 'toggleLoadingBarStatus', 'setButtonStatusBar']),

    ...mapActions('notifications', [ 'toggleNotification', 'setNotificationMessage' ]),

    getHeadClass (headName, tableName) {
      if (headName === 'billings' || (headName === 'registers' && tableName !== 'importedReturns')) {
        return 'black-300'
      }

      if (headName === 'paymentMethod') {
        return 'column-100'
      }
    },

    solveColumnItem (item, key) {
      if (key === 'id' || key === 'bankCode' || key === 'walletId') {
        return 'hidden'
      }

      if (!isNaN(item) && key !== 'id') {
        return 'center aligned'
      }
    },

    getProcessRequest(idWallet, paymentMethod) {
      this.disableAction = true
      const { commit } = this.$store
      this.fileDataFromTable = { idWallet, paymentMethod }
      commit('files/SET_FILE_DATA', this.fileDataFromTable)
      const { currentYear, currentMonty } = this.$store.state.files
      this.disableAction = false
      createOutputProcessRequest( idWallet ).then(result => {
        this.getYearListFiles()
        this.getFilesNewShipment()
        this.getFilesWaitingReturn({ year: currentYear, month: currentMonty })
        this.disableAction = false
        this.setNotificationMessage("Arquivo enviado para geração!")
        this.toggleNotification({ active: true, type: 'success' })
      }).catch(e => {
        Vue.$log.error('error', e)
        commit('files/SET_FILE_DATA', this.fileDataFromTable)
        this.disableAction = false
        this.toggleNotification({ active: true, type: 'error' })
      })
    },

    bindPlugin () {
      Vue.nextTick().then(() => {
        $(`.extra-options .tui.ui.dropdown`).dropdown()
      })
    }

  }
}
